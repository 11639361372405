import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import { getCurrentUser } from "../auth/authController";
import GetSeriesHook from "../../hook/questions/get-series-hook";
import { Row, Col, Card, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa";

import "./quiz.css";
import notify from "../../hook/useNotifaction";
import AudioPlayer from "../../components/audioPlayer";
import startButton from "../../assets/start-button.png";
import pauseButton from "../../assets/pause-button.png";

const ExamQuiz = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const questionSerie = searchParams.get("questionSerie");
  const parsedData = JSON.parse(decodeURIComponent(questionSerie));
  const intervalIdRef = useRef(null);
  const initialTime = 30;
  const [questions, totalPages, currentPage] = GetSeriesHook(parsedData);

  const [img, setImg] = useState();
  const [answerInput, setAnswerInput] = useState("");
  const [userResponses, setUserResponses] = useState([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const [questionCorrection, setQuestionCorrection] = useState("");

  const [next, setNext] = useState("تأكيد");
  const [errorText, srtErrorText] = useState("0000");

  const [correctNumbers, setCorrectNumbers] = useState([]);
  const [incorrectNumbers, setIncorrectNumbers] = useState([]);

  const [waitingNumbers, setWaitingNumbers] = useState([]);

  const [timer, setTimer] = useState(null);

  const [isTimerInitialized, setIsTimerInitialized] = useState(false);

  const [timerButton, setTimerButton] = useState(pauseButton);

  const [handleEmptyQue, setHandleEmptyQue] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  function handleAnswerInput(number) {
    if (isQuestionVisible) {
      if (answerInput.length < 4) {
        if (!answerInput.includes(number)) {
          setAnswerInput((prevInput) => prevInput + number);
        } else {
          setAnswerInput((prevInput) =>
            prevInput.replace(new RegExp(number, "g"), "")
          );
        }
      }
    }
  }
  // console.log(answerInput);

  useEffect(() => {
    const selectedAnswer = answerInput
      .split("")
      .sort()
      .map((number) => +number);
    setWaitingNumbers(selectedAnswer);
  }, [answerInput]);

  function clearAnswerInput() {
    setAnswerInput("");
    setCorrectNumbers([]);
    setIncorrectNumbers([]);
  }

  const validateAnswer = () => {
    setNext("التالي");
    setTimer(null);
    setQuestionCorrection(questions[currentQuestionIndex].correction);
    const selectedAnswer = answerInput
      .split("")
      .sort()
      .map((number) => +number);

    const correctAnswer = questions[currentQuestionIndex].answer
      .toString()
      .split("")
      .sort()
      .map((number) => +number);

    if (String(selectedAnswer) !== String(correctAnswer)) {
      srtErrorText("الجواب خطأ");
    } else {
      srtErrorText("الجواب صحيح");
    }

    const correct = [];
    const incorrect = [];

    selectedAnswer.forEach((number) => {
      if (correctAnswer.includes(number)) {
        correct.push(number);
      } else {
        incorrect.push(number);
      }
    });

    setCorrectNumbers(correct);
    setIncorrectNumbers(incorrect);
    setUserResponses((prevResponses) => [
      ...prevResponses,
      {
        _id: questions[currentQuestionIndex]._id,
        selectedAnswer,
      },
    ]);

    // Add any missing correct numbers that the user did not select
    correctAnswer.forEach((number) => {
      if (!correct.includes(number)) {
        correct.push(number);
      }
    });

    setIsAnswerCorrect(true);
  };

  const handleEmptyQuestion = () => {
    if (isQuestionVisible) {
      validateAnswer();
      setIsQuestionVisible(false);
      return; // Prevent immediate question switch
    }

    if (!isQuestionVisible) {
      setUserResponses((prevResponses) => [
        ...prevResponses,
        {
          _id: questions[currentQuestionIndex]._id,
          selectedAnswer: +"00",
        },
      ]);

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setNext("تأكيد");
        setTimer(null);
        setIsAnswerCorrect(null);
        setIsQuestionVisible(true);
        setQuestionCorrection("");
        clearAnswerInput();
      } else {
        if (isAnswerCorrect) {
          setQuizCompleted(true);
        }
      }
    }
  };

  const handleNextQuestion = () => {
    if (answerInput === "") {
      notify("أدخل الجواب أولا", "warn");
      return;
    }
    if (isQuestionVisible) {
      setTimer(null);
      validateAnswer();
      setIsQuestionVisible(false);
      return;
    }
    const correctAnswer = questions[currentQuestionIndex].answer
      .toString()
      .split("")
      .sort()
      .map((number) => +number);

    if (!isQuestionVisible) {
      setUserResponses((prevResponses) => [
        ...prevResponses,
        {
          _id: questions[currentQuestionIndex]._id,
          correctAnswer,
          selectedAnswer: +"00",
        },
      ]);

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setNext("تأكيد");
        setTimer(null);
        setIsAnswerCorrect(null);
        setIsQuestionVisible(true);
        setQuestionCorrection("");
        clearAnswerInput();
      } else {
        if (isAnswerCorrect) {
          setQuizCompleted(true);
        }
      }
    }
  };

  const filterOddIndices = (arr) => arr.filter((_, index) => index % 2 === 0);
  const finalResponses = filterOddIndices(userResponses);

  useEffect(() => {
    let intervalId;

    if (timer > 0 && isTimerInitialized) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(intervalId);
      handleEmptyQuestion();
      setHandleEmptyQue(true);
    }

    return () => clearInterval(intervalId);
  }, [timer, isTimerInitialized]);

  const startTimer = () => {
    setTimer(initialTime);
    setIsTimerInitialized(true);
  };
  const handleAudioEnded = () => {
    startTimer();
  };

  const stopTimer = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
    setIsTimerInitialized(false);
  };
  const goTimer = () => {
    if (!isTimerInitialized) {
      // Only start if not already initialized
      setIsTimerInitialized(true);
    }
  };

  const handleTimerButton = (e) => {
    if (e.target.src === pauseButton) {
      setTimerButton(startButton);
      stopTimer();
    } else {
      setTimerButton(pauseButton);
      goTimer();
    }
    // e.target.src = startButton;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const [timerAudio, setTimerAudio] = useState(null);
  const [showAudio, setShowAudio] = useState(false); // State to control audio display

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      setTimerAudio(2); // Set timer to 5 seconds
      setShowAudio(false); // Hide audio initially
      const countdown = setInterval(() => {
        setTimerAudio((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setShowAudio(true); // Show audio after timer ends
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Decrease timer every second

      return () => clearInterval(countdown); // Clean up on unmount
    } else {
      setShowAudio(true); // Always show audio if not the first question
    }
  }, [currentQuestionIndex]);

  return (
    <div className="quiz-zone">
      <div className="audio-volume">
        {isMuted ? (
          <FaVolumeMute onClick={toggleMute} />
        ) : (
          <FaVolumeUp onClick={toggleMute} />
        )}
      </div>
      {questions && questions.length > 0 && (
        <div className="container-md-only">
          <img
            className="main-questions-image"
            src={questions[currentQuestionIndex].questionImage}
            alt="question"
          />
        </div>
      )}
      <Container>
        <div className="quiz">
          {questions && questions.length > 0 && (
            <div>
              {questions[currentQuestionIndex] &&
              questions[currentQuestionIndex].audio &&
              showAudio ? (
                <AudioPlayer
                  audioUrl={questions[currentQuestionIndex].audio}
                  onAudioEnded={handleAudioEnded}
                  muted={isMuted}
                />
              ) : (
                ""
              )}

              <div
                className="d-flex"
                style={{
                  marginTop: "25px",
                }}
              >
                <p
                  className="question text-light p-0 d-flex "
                  style={{ width: "fit-content" }}
                >
                  <div className="question-index">
                    <span className="number-font">
                      {currentQuestionIndex + 1}
                    </span>
                  </div>
                </p>
                <p className="question text-light">
                  {questions[currentQuestionIndex].question}
                </p>
                <p
                  className="question text-light p-0 d-flex m-0"
                  style={{ width: "fit-content" }}
                >
                  <div className="score">
                    <span className="number-font">
                      {timer === null ? `${initialTime}` : timer}
                    </span>
                  </div>
                </p>
              </div>

              <div className="question-area">
                {!questions[currentQuestionIndex].question2 ? (
                  <Row>
                    {questions[currentQuestionIndex].options.map(
                      (option, index) => (
                        <Col
                          sm={12}
                          md={6}
                          className="d-flex align-items-center"
                        >
                          <button
                            key={index}
                            className={
                              correctNumbers.includes(index + 1)
                                ? "op success number-font"
                                : incorrectNumbers.includes(index + 1)
                                ? "op wrong  number-font"
                                : waitingNumbers.includes(index + 1)
                                ? "op waiting number-font"
                                : "op number-font"
                            }
                            onClick={() => handleAnswerInput(index + 1)}
                          >
                            {`${index + 1}`}
                          </button>
                          <div
                            className=" option bg-light"
                            // className="option bg-light"
                          >
                            {`${option}`}
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                ) : (
                  <>
                    {/* Render options for the first question */}
                    <Row>
                      {questions[currentQuestionIndex].options
                        .slice(0, 2)
                        .map((option, index) => (
                          <Col
                            sm={12}
                            md={6}
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <button
                              className={
                                correctNumbers.includes(index + 1)
                                  ? "op success number-font"
                                  : incorrectNumbers.includes(index + 1)
                                  ? "op wrong  number-font"
                                  : waitingNumbers.includes(index + 1)
                                  ? "op waiting number-font"
                                  : "op number-font"
                              }
                              onClick={() => handleAnswerInput(index + 1)}
                            >
                              {index + 1}
                            </button>
                            <div
                              className="option bg-light"
                              // className="option bg-light"
                            >
                              {option}
                            </div>
                          </Col>
                        ))}
                    </Row>

                    {/* Display the second question */}

                    <p className="question text-light">
                      {questions[currentQuestionIndex].question2}
                    </p>

                    {/* Render options for the second question */}
                    <Row>
                      {questions[currentQuestionIndex].options
                        .slice(2)
                        .map((option, index) => (
                          <Col
                            sm={12}
                            md={6}
                            key={index + 2}
                            className="d-flex align-items-center"
                          >
                            <button
                              className={
                                correctNumbers.includes(index + 3)
                                  ? "op success number-font"
                                  : incorrectNumbers.includes(index + 3)
                                  ? "op wrong  number-font"
                                  : waitingNumbers.includes(index + 3)
                                  ? "op waiting number-font"
                                  : "op number-font"
                              }
                              onClick={() => handleAnswerInput(index + 3)}
                            >
                              {index + 3}
                            </button>
                            <div
                              className="option bg-light"
                              // className="option bg-light"
                            >
                              {option}
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </>
                )}

                {questionCorrection && (
                  <div
                    className={
                      errorText === "الجواب خطأ"
                        ? "wrong response"
                        : "success response"
                    }
                  >
                    <header className="d-flex align-items-center">
                      {errorText === "الجواب خطأ" ? (
                        <img
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEuOTc2IDUxLjk3NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEuOTc2IDUxLjk3NjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+DQo8Zz4NCgk8cGF0aCBkPSJNNDQuMzczLDcuNjAzYy0xMC4xMzctMTAuMTM3LTI2LjYzMi0xMC4xMzgtMzYuNzcsMGMtMTAuMTM4LDEwLjEzOC0xMC4xMzcsMjYuNjMyLDAsMzYuNzdzMjYuNjMyLDEwLjEzOCwzNi43NywwICAgQzU0LjUxLDM0LjIzNSw1NC41MSwxNy43NCw0NC4zNzMsNy42MDN6IE0zNi4yNDEsMzYuMjQxYy0wLjc4MSwwLjc4MS0yLjA0NywwLjc4MS0yLjgyOCwwbC03LjQyNS03LjQyNWwtNy43NzgsNy43NzggICBjLTAuNzgxLDAuNzgxLTIuMDQ3LDAuNzgxLTIuODI4LDBjLTAuNzgxLTAuNzgxLTAuNzgxLTIuMDQ3LDAtMi44MjhsNy43NzgtNy43NzhsLTcuNDI1LTcuNDI1Yy0wLjc4MS0wLjc4MS0wLjc4MS0yLjA0OCwwLTIuODI4ICAgYzAuNzgxLTAuNzgxLDIuMDQ3LTAuNzgxLDIuODI4LDBsNy40MjUsNy40MjVsNy4wNzEtNy4wNzFjMC43ODEtMC43ODEsMi4wNDctMC43ODEsMi44MjgsMGMwLjc4MSwwLjc4MSwwLjc4MSwyLjA0NywwLDIuODI4ICAgbC03LjA3MSw3LjA3MWw3LjQyNSw3LjQyNUMzNy4wMjIsMzQuMTk0LDM3LjAyMiwzNS40NiwzNi4yNDEsMzYuMjQxeiIgZmlsbD0iI0ZGRkZGRiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo="
                          alt="incorrect"
                        />
                      ) : (
                        <img
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA0NCA0NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDQgNDQiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+DQogIDxwYXRoIGQ9Im0yMiwwYy0xMi4yLDAtMjIsOS44LTIyLDIyczkuOCwyMiAyMiwyMiAyMi05LjggMjItMjItOS44LTIyLTIyLTIyem0xMi43LDE1LjFsMCwwLTE2LDE2LjZjLTAuMiwwLjItMC40LDAuMy0wLjcsMC4zLTAuMywwLTAuNi0wLjEtMC43LTAuM2wtNy44LTguNC0uMi0uMmMtMC4yLTAuMi0wLjMtMC41LTAuMy0wLjdzMC4xLTAuNSAwLjMtMC43bDEuNC0xLjRjMC40LTAuNCAxLTAuNCAxLjQsMGwuMSwuMSA1LjUsNS45YzAuMiwwLjIgMC41LDAuMiAwLjcsMGwxMy40LTEzLjloMC4xYzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC4zIDAuNCwwLjkgMCwxLjN6IiBmaWxsPSIjRkZGRkZGIi8+DQo8L3N2Zz4NCg=="
                          alt="correct"
                        />
                      )}
                      <span>{errorText}</span>
                    </header>
                    <div className="question-correction">
                      {questionCorrection}
                    </div>
                  </div>
                )}

                {quizCompleted ? (
                  <Link
                    className="btn-number validate-button border-0"
                    // to={{
                    //   pathname: "/result",
                    //   state: {
                    //     finalResponses: finalResponses,
                    //     questionSerie: parsedData,
                    //   },
                    // }}
                    to={`/result?result=${encodeURIComponent(
                      JSON.stringify(finalResponses)
                    )}&questionSerie=${encodeURIComponent(
                      JSON.stringify(parsedData)
                    )}`}
                  >
                    النتيجة
                  </Link>
                ) : (
                  <button
                    style={
                      next === "التالي"
                        ? { backgroundColor: "rgb(0, 214, 147)" }
                        : {}
                    }
                    onClick={
                      handleEmptyQue ? handleEmptyQuestion : handleNextQuestion
                    }
                    className="btn-number validate-button border-0 mb-3"
                  >
                    {next}
                  </button>
                )}
                <button
                  onClick={clearAnswerInput}
                  className="btn-number delete-button border-0"
                >
                  تصحيح
                </button>
              </div>
              {timer === null ? (
                <div className="timer-button-holder">
                  <img className="timer-button" src={timerButton} />
                </div>
              ) : (
                <div className="timer-button-holder">
                  <img
                    className="timer-button"
                    onClick={handleTimerButton}
                    src={timerButton}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ExamQuiz;
