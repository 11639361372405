import React from "react";
import LicenseType from "../../components/licenseType/licenseType";
import Hero from "../../components/welcome/hero";
import heroImage from "../../assets/Hero.png";

const HomePage = () => {
  return (
    <>
      <Hero
        img={heroImage}
        title="أحصل على رخصة سياقتك في 7 أيام مع منصة الكود"
        description="إستعد جيداً لإمتحان الحصول على رخصة السياقة"
        tip="قم بإجراء باختبار مجاني الآن. يمكنك البدء فورًا من خلال الإختبارات المجانية بجودة عالية HD."
      />
      <LicenseType />
    </>
  );
};

export default HomePage;
