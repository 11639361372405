import React from "react";
import Hero from "../../components/welcome/hero";
import Questions from "../../components/questions/questions";
import heroImage from "../../assets/Almoniteur_logo.png";

const Series = () => {
  return (
    <div>
      <Hero
        img={heroImage}
        title="مرحبا بكم في منصة 'الكود' لتعليم السياقة حسب التحديث الأخير لبنك الأسئلة 2025"
        description="يمكنكم متابعة البث المباشر كل يوم على جميع منصات التواصل الإجتماعي على الساعة  9 مساءا  "
      />
      <Questions />
    </div>
  );
};

export default Series;
