import React from "react";
import { Container } from "react-bootstrap";

const TermsOfService = () => {
  return (
    <div
      className="terms"
      style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
    >
      <Container>
        <h1>شروط الإستخدام لموقع alcode.ma</h1>
        <p>
          <strong>مرحبًا بك في موقع alcode.ma!</strong>
        </p>
        <p>
          يرجى قراءة شروط الخدمة التالية بعناية. باستخدامك لهذا الموقع، فإنك
          توافق على الالتزام بهذه الشروط. إذا كنت لا توافق على أي جزء من هذه
          الشروط، فلا يجب عليك استخدام الموقع.
        </p>
        <h2>1. تعريفات</h2>
        <h3>1.1 الموقع</h3>
        <p>
          "الموقع" يشير إلى منصة alcode.ma، بما في ذلك جميع الصفحات والمحتويات
          والخدمات المقدمة.
        </p>
        <h3>1.2 المستخدم</h3>
        <p>"المستخدم" هو أي شخص يقوم بزيارة الموقع أو استخدام خدماته.</p>
        <h3>1.3 المحتوى</h3>
        <p>
          "المحتوى" يشمل جميع النصوص والصور والفيديوهات والأسئلة والإجابات وأي
          مواد أخرى موجودة على الموقع.
        </p>
        <h2>2. قبول الشروط</h2>
        <p>
          عند دخولك إلى الموقع، فإنك تعبر عن موافقتك الكاملة على شروط الخدمة.
          يحتفظ الموقع بالحق في تعديل هذه الشروط في أي وقت دون إشعار مسبق،
          وستكون التعديلات سارية فور نشرها.
        </p>
        <h2>3. استخدام الموقع</h2>
        <h3>3.1 الأغراض المسموح بها</h3>
        <p>
          يُسمح لك باستخدام الموقع لأغراض شخصية وتعليمية فقط. يُمنع استخدام
          الموقع بأي شكل قد يؤثر سلبًا على أداء الموقع أو على تجربة المستخدمين
          الآخرين.
        </p>
        <h3>3.2 التسجيل</h3>
        <p>
          إذا كان عليك إنشاء حساب، يجب عليك تقديم معلومات دقيقة ومحدثة. يتحمل
          المستخدم المسؤولية الكاملة عن الحفاظ على سرية معلومات حسابه وأي نشاط
          يحدث تحت حسابه.
        </p>
        <h3>3.3 سلوك المستخدم</h3>
        <p>
          يجب على المستخدمين الامتناع عن نشر أي محتوى غير قانوني أو مسيء أو
          ينتهك حقوق الآخرين. يُحظر استخدام أي أسلوب للتخريب أو التلاعب بالنظام،
          بما في ذلك استخدام البرمجيات الضارة.
        </p>
        <h2>4. المحتوى</h2>
        <h3>4.1 حقوق الملكية الفكرية</h3>
        <p>
          جميع المحتويات الموجودة على الموقع، بما في ذلك النصوص والصور، هي ملك
          للموقع أو لمزودي المحتوى. لا يُسمح لك بإعادة إنتاج أو توزيع أو تعديل
          المحتوى دون إذن مسبق.
        </p>
        <h3>4.2 دقة المعلومات</h3>
        <p>
          نبذل جهودًا كبيرة لضمان دقة المعلومات، لكن لا يمكننا ضمان خلو المحتوى
          من الأخطاء أو الانقطاع. استخدامك للمعلومات هو على مسؤوليتك الخاصة،
          وننبهك بأهمية التحقق من المعلومات قبل الاعتماد عليها.
        </p>
        <h2>5. التعديلات</h2>
        <h3>5.1 تعديل الشروط</h3>
        <p>
          يحتفظ الموقع بالحق في تعديل شروط الخدمة في أي وقت. سنقوم بنشر
          التعديلات على الموقع، وستكون التعديلات سارية فور نشرها. من مسؤوليتك
          مراجعة الشروط بشكل دوري.
        </p>
        <h3>5.2 تأثير التعديلات</h3>
        <p>استمرار استخدامك للموقع بعد أي تعديل يعني أنك تقبل تلك التغييرات.</p>
        <h2>6. إخلاء المسؤولية</h2>
        <h3>6.1 الأضرار</h3>
        <p>
          لا يتحمل الموقع أي مسؤولية عن الأضرار المباشرة أو غير المباشرة أو
          العرضية الناجمة عن استخدام الموقع أو الاعتماد على أي محتوى. قد تتضمن
          الأضرار فقدان البيانات أو الأرباح أو انقطاع الخدمة.
        </p>
        <h3>6.2 الروابط الخارجية</h3>
        <p>
          قد يحتوي الموقع على روابط لمواقع خارجية. نحن غير مسؤولين عن محتوى أو
          سياسات الخصوصية الخاصة بتلك المواقع. نوصي بمراجعة شروط الخدمة وسياسات
          الخصوصية لأي موقع تزوره.
        </p>
        <h2>7. الخصوصية</h2>
        <h3>7.1 جمع المعلومات</h3>
        <p>
          نقوم بجمع معلومات شخصية عند التسجيل أو الاشتراك في خدمات معينة. هذه
          المعلومات تُستخدم لتحسين تجربتك على الموقع.
        </p>
        <h3>7.2 استخدام المعلومات</h3>
        <p>
          نستخدم المعلومات المقدمة لتحسين خدماتنا وتخصيص التجربة. لن نقوم ببيع
          أو تأجير معلوماتك لأي طرف ثالث.
        </p>
        <h3>7.3 حقوق المستخدم</h3>
        <p>
          يحق لك الوصول إلى معلوماتك الشخصية وتصحيحها أو طلب حذفها. يمكنك القيام
          بذلك عن طريق التواصل معنا عبر وسائل الاتصال المحددة.
        </p>
        <h2>8. القوانين المعمول بها</h2>
        <p>
          تخضع شروط الخدمة هذه للقوانين المعمول بها في المملكة المغربية. في حالة
          حدوث أي نزاع، سيخضع للاختصاص الحصري للمحاكم في المغرب.
        </p>
        <p>
          <strong>
            شكرًا لك على استخدام موقع alcode.ma! نتمنى لك تجربة تعليمية ممتعة
            ومفيدة.
          </strong>
        </p>{" "}
      </Container>
    </div>
  );
};

export default TermsOfService;
