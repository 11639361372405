import { GET_ALL_QUESTIONS, GET_ONE_QUESTION, GET_ERROR } from "../type";

const initial = {
  questions: [],
  oneQuestion: [],
  loading: true,
};
const questionReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_ALL_QUESTIONS:
      return {
        ...state,
        loading: false,
        questions: action.payload,
      };
    case GET_ONE_QUESTION:
      return {
        loading: false,
        oneQuestion: action.payload,
      };
    case GET_ERROR:
      return {
        loading: true,
        questions: action.payload,
      };

    default:
      return state;
  }
};

export default questionReducer;
