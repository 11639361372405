import React from "react";
import "./quiz.css";

import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import book from "../../assets/book.png";
import clock from "../../assets/clock.png";

const QuizType = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const questionSerie = searchParams.get("questionSerie");
  // const parsedDataArray = JSON.parse(decodeURIComponent(dataParam));

  // console.log(questionSerie);

  return (
    <div className="quiz-type">
      <Container>
        <Row className="quiz-row d-flex align-items-center">
          <Col sm={12} md={6}>
            <div className="box exam">
              <Link
                to={`/quiz/exam?questionSerie=${encodeURIComponent(
                  JSON.stringify(questionSerie)
                )}`}
              >
                <h2>وضع الإمتحان</h2>
                <p>
                  <BsCheck /> ستظهر النتيجة والإجابات في آخر الإختبار
                </p>
                <div className=" text-center">
                  <img src={clock} alt="" />
                </div>
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="box training ">
              <Link
                to={`/quiz/training?questionSerie=${encodeURIComponent(
                  JSON.stringify(+questionSerie)
                )}`}
              >
                <h2>وضع التدريب</h2>
                <p>
                  <BsCheck /> ستظهر الإجابة الصحيحة بعد كل سؤال
                </p>
                <div className=" text-center">
                  <img src={book} alt="" />
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QuizType;
