import React, { useEffect, useState } from "react";
import "./result.css";
import axios from "axios";

import { json, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../auth/authController";
import GetSeriesHook from "../../hook/questions/get-series-hook";
import { Col, Row } from "react-bootstrap";

const Result = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const dataParam = searchParams.get("result");
  const questionSerie = searchParams.get("questionSerie");
  const questionSerieParsed = JSON.parse(decodeURIComponent(questionSerie));
  const resultparsedDataArray = JSON.parse(decodeURIComponent(dataParam));

  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  let [answers, setAnswers] = useState([]);
  const [results, setResults] = useState(resultparsedDataArray);
  // const [totalPages, setTotalPages] = useState();

  const currentUserApiKey = getCurrentUser();

  const [questions, totalPages, currentPage] = GetSeriesHook(
    +questionSerieParsed
  );
  useEffect(() => {
    setAnswers(questions);
  }, [questions]);

  useEffect(() => {
    // Calculate correct answers count when answers change
    let count = 0;
    answers.forEach((answer, index) => {
      if (
        JSON.stringify(String(answer?.answer).split("").map(Number)) ===
        JSON.stringify(results[index]?.selectedAnswer)
      ) {
        // console.log(answer);
        count++;
      }
    });
    setCorrectAnswersCount(count);
  }, [answers]);

  const handleBoxClick = (id, index) => {
    window.location.href = `/QuestionAnswer/${id}?index=${encodeURIComponent(
      JSON.stringify(index)
    )}`;
  };

  return (
    <div className="result container">
      <div className="result--card p-4">
        <h4>السلسلة رقم : {questionSerieParsed}</h4>
        <h1>
          نتيجتكم هي : {correctAnswersCount}/{resultparsedDataArray.length}
        </h1>
        {correctAnswersCount >= 32 ? (
          <h1>لقد إجتزتم الإمتحان بنجــــاح</h1>
        ) : (
          <h1>للأسف لم تجتاز الإمتحان بنجاح ، راجع دروسك</h1>
        )}
      </div>
      {answers && answers.length > 0 && (
        <Row
          style={{
            direction: "ltr",
          }}
        >
          {answers.map((answer, index) => {
            return (
              <Col sm={3} xs={3} md={2} lg={1} className=" mb-3">
                <div
                  className="results-box "
                  key={answer._id}
                  onClick={() => handleBoxClick(answer._id, index + 1)}
                  style={{
                    backgroundColor:
                      JSON.stringify(
                        String(answers[index]?.answer).split("").map(Number)
                      ) === JSON.stringify(results[index]?.selectedAnswer)
                        ? "rgb(0, 214, 147)"
                        : "rgb(251, 82, 82)",
                  }}
                >
                  {/* <h2>{answer.answer}</h2> */}
                  <p className="text-light number-font">{index + 1}</p>
                  <hr />
                  <p className="number-font">
                    {answers[index] &&
                    results[index] &&
                    answers[index]._id === results[index]._id
                      ? answers[index].answer
                      : "00"}
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default Result;
