import { useLocation, useParams, useNavigate } from "react-router-dom";
import GetOneQuestionHook from "../../hook/questions/get-question-hook";
import { Row, Col, Container } from "react-bootstrap";

const QuestionAnswer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);

  const [question, totalPages, currentPage] = GetOneQuestionHook(id);

  const index = searchParams.get("index");

  // console.log(question);

  // const correctNumbers = String(question.answer);

  // console.log(split);
  const split = String(question.answer).split("").map(Number);

  return (
    <div className="quiz-zone">
      <div className="container-md-only">
        <img
          width={"100%"}
          src={
            // require("../../assets/autoecole1.jpg")
            question.questionImage
          }
          alt="question"
          className="responsive-image"
        />
      </div>
      <div className="container">
        <div className="quiz">
          <div className="quiz--app">
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "10px" }}
            >
              <p
                className="question text-light p-0 d-flex "
                style={{ width: "fit-content" }}
              >
                <div className="question-index">
                  <span className="number-font">{index}</span>
                </div>
              </p>
              <div className="d-flex align-items-center">
                <p className="question text-light">{question.question}</p>
              </div>
            </div>
            {/* <img src={require("../../assets/autoecole1.jpg")} alt="" /> */}
            <div className="question-area">
              {!question.question2 ? (
                <Row>
                  {question &&
                    question.answer &&
                    question.options &&
                    question.options.map((option, index) => (
                      <Col sm={12} md={6} className="d-flex align-items-center">
                        <button
                          key={index}
                          className={
                            split.includes(index + 1)
                              ? "op success number-font"
                              : "op number-font"
                          }
                        >
                          {`${index + 1}`}
                        </button>
                        <div
                          className={
                            split.includes(index + 1)
                              ? "success  option"
                              : "option bg-light"
                          }
                          // className="option bg-light"
                        >
                          {`${option}`}
                        </div>
                      </Col>
                    ))}
                </Row>
              ) : (
                <>
                  <Row>
                    {question &&
                      question.answer &&
                      question.options &&
                      question.options.slice(0, 2).map((option, index) => (
                        <Col
                          sm={12}
                          md={6}
                          className="d-flex align-items-center"
                        >
                          <button
                            key={index}
                            className={
                              split.includes(index + 1)
                                ? "op success "
                                : "op number-font"
                            }
                          >
                            {`${index + 1}`}
                          </button>
                          <div
                            className={
                              split.includes(index + 1)
                                ? "success option"
                                : "option bg-light"
                            }
                            // className="option bg-light"
                          >
                            {`${option}`}
                          </div>
                        </Col>
                      ))}
                  </Row>
                  <p className="question text-light">{question.question2}</p>
                  <Row>
                    {question &&
                      question.answer &&
                      question.options &&
                      question.options.slice(2, 4).map((option, index) => (
                        <Col
                          sm={12}
                          md={6}
                          className="d-flex align-items-center"
                        >
                          <button
                            key={index}
                            className={
                              split.includes(index + 3)
                                ? "op success "
                                : "op number-font"
                            }
                          >
                            {`${index + 3}`}
                          </button>
                          <div
                            className={
                              split.includes(index + 3)
                                ? "success option"
                                : "option bg-light"
                            }
                            // className="option bg-light"
                          >
                            {`${option}`}
                          </div>
                        </Col>
                      ))}
                  </Row>
                </>
              )}
              {question.correction && (
                <div className="success response">
                  <header className="d-flex align-items-center">
                    <img
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA0NCA0NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDQgNDQiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+DQogIDxwYXRoIGQ9Im0yMiwwYy0xMi4yLDAtMjIsOS44LTIyLDIyczkuOCwyMiAyMiwyMiAyMi05LjggMjItMjItOS44LTIyLTIyLTIyem0xMi43LDE1LjFsMCwwLTE2LDE2LjZjLTAuMiwwLjItMC40LDAuMy0wLjcsMC4zLTAuMywwLTAuNi0wLjEtMC43LTAuM2wtNy44LTguNC0uMi0uMmMtMC4yLTAuMi0wLjMtMC41LTAuMy0wLjdzMC4xLTAuNSAwLjMtMC43bDEuNC0xLjRjMC40LTAuNCAxLTAuNCAxLjQsMGwuMSwuMSA1LjUsNS45YzAuMiwwLjIgMC41LDAuMiAwLjcsMGwxMy40LTEzLjloMC4xYzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC4zIDAuNCwwLjkgMCwxLjN6IiBmaWxsPSIjRkZGRkZGIi8+DQo8L3N2Zz4NCg=="
                      alt="correct"
                    />
                    <span>الجواب الصحيح</span>
                  </header>
                  <div className="question-correction">
                    {question.correction}
                  </div>
                </div>
              )}
              <a className="btn-number validate-button border-0 mb-3">
                النتيجة
              </a>
              <a
                className="btn-number delete-button border-0"
                onClick={() => navigate(-1)}
              >
                الرجوع للتصحيح
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswer;
