import { GET_ALL_QUESTIONS, GET_ONE_QUESTION, GET_ERROR } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";

export const getAllSeries = (page, limit) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/questions?page=${page}&limit=${limit}`
    );
    dispatch({
      type: GET_ALL_QUESTIONS,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "error " + e,
    });
  }
};
export const getOneQuestion = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/questions/${id}`);
    dispatch({
      type: GET_ONE_QUESTION,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: GET_ERROR,
      payload: "error " + e,
    });
  }
};
