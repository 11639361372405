import axios from "axios";

// const baseUrl = axios.create({ baseURL: "http://localhost:3001" });
const baseUrl = axios.create({
  baseURL: "https://alcode-1.onrender.com",
  // baseURL: "https://alcode.onrender.com",
  // baseURL: "http://localhost:3001",
});

export default baseUrl;
