import React from "react";
import img from "../../assets/NotFoundPage.png";
function NotFoundPage() {
  return (
    <div
      style={{ backgroundColor: "#fcc10a", minHeight: "calc(100vh - 104px)" }}
      className=" text-center"
    >
      <img src={img} alt="" style={{ maxWidth: "100%" }} />
      <h1 className="mb-3" style={{ color: "#2d475c" }}>
        هذه الصفحة غير موجودة
      </h1>
      <a
        href="/"
        style={{
          padding: "8px 12px",
          color: "#fcc10a",
          background: "#2d475c",
          borderRadius: "6px",
        }}
      >
        العودة للصفحة الرئيسية
      </a>
    </div>
  );
}
export default NotFoundPage;
