import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container } from "react-bootstrap";
import { FcStatistics } from "react-icons/fc";
import carIcon from "../../assets/car-icon.png";
import truckCIcon from "../../assets/truckC-icon.png";
import truckECIcon from "../../assets/truckEC-icon.png";
import motocycleIcon from "../../assets/motocycle-icon.png";
import busIcon from "../../assets/bus-icon.png";

const LicenseType = () => {
  const totalSeries = 4;
  const data = [
    {
      type: "A -- الدراجة",
      allowed: false,
      image: motocycleIcon,
    },
    {
      type: "B -- السيارة",
      allowed: true,
      image: carIcon,
    },
    {
      type: "C -- الشاحنات",
      allowed: false,
      image: truckCIcon,
    },
    {
      type: "D -- الحافلات",
      allowed: false,
      image: busIcon,
    },
    {
      type: "EC -- الشاحنات الكبيرة",
      allowed: false,
      image: truckECIcon,
    },
  ];
  return (
    <section>
      <Container>
        <Row>
          {data.map((item, index) => (
            <Col key={index} sm={12} md={6} lg={4}>
              <Link
                to={item.allowed ? `/series` : "/"}
                className="text-decoration-none"
              >
                <Card
                  className="text-center d-flex flex-column align-items-center mb-3"
                  style={
                    item.allowed
                      ? {}
                      : {
                          filter: "grayscale(1)",
                          backgroundColor: "#cacaca",
                          cursor: "not-allowed",
                        }
                  }
                >
                  <div className="bs-icon-lg bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block ">
                    <img width={"30%"} src={item.image} alt="" />
                    {/* <FcStatistics style={{ fontSize: "64px" }} /> */}
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontWeight: "bold" }}>
                      صنف {item.type}
                    </Card.Title>
                    <Card.Text>
                      {item.allowed ? "إبدأ الآن" : "غير متوفر حاليا"}
                    </Card.Text>
                    <Card.Text
                      style={{ width: "45%", margin: "auto" }}
                      className="text-bg-warning fw-bold rounded-pill"
                    >
                      إبدأ الآن{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default LicenseType;
