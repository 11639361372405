import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Result from "./components/result/result";
import QuestionAnswer from "./components/questionAnswer/QuestionAnswer";
// import ExamQuiz from "./components/quiz/examQuiz";
// import TrainingQuiz from "./components/quiz/trainingQuiz";
// import QuizType from "./components/quiz/quizType";
import Login from "./components/auth/login";
import Register from "./components/auth/Register";
import NavBar from "./components/navBar/navBar";
import Series from "./pages/seriesPage/Series";
import Footer from "./components/footer/footer";
import HomePage from "./pages/homePage/homePage";
import QuizType from "./pages/quiz/quizType";
import ExamQuiz from "./pages/quiz/examQuiz";
import TrainingQuiz from "./pages/quiz/trainingQuiz";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";

function App() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          {/* <span class="loader"></span> */}
          {/* .loader {
          position: relative;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    animation: rotate 0.75s linear infinite;
  }
  .loader::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 40px;
    border: 1px solid #FF3D00;
    border-width: 12px 2px 7px;
    border-radius: 2px 2px 1px 1px;
    box-sizing: border-box;
    transform: rotate(45deg) translate(24px, -10px);
    background-image: linear-gradient(#FF3D00 20px, transparent 0),
    linear-gradient(#FF3D00 30px, transparent 0),
    linear-gradient(#FF3D00 30px, transparent 0);
    background-size: 10px 12px , 1px 30px, 1px 30px;
    background-repeat: no-repeat;
    background-position: center , 12px 0px , 3px 0px;
}
  .loader::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    left: 20px;
    top: 47px;
    border-radius: 50%;
    color: #Fff;
    box-shadow: -4px 7px 2px, -7px 16px 3px 1px,
      -11px 24px 4px 1px, -6px 24px 4px 1px,
      -14px 35px 6px 2px, -5px 36px 8px 2px,
      -5px 45px 8px 2px, -14px 49px 8px 2px,
      6px 60px 11px 1px, -11px 66px 11px 1px,
      11px 75px 13px, -1px 82px 15px;
  }

@keyframes rotate {
  to{transform:rotate(360deg)   }
} */}

          <Route path="/" element={<HomePage />} />
          <Route path="/series" element={<Series />} />
          <Route path="/quiz" element={<QuizType />} />
          <Route path="/result" element={<Result />} />
          <Route path="/QuestionAnswer/:id" element={<QuestionAnswer />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/quiz" element={<QuizType />} />
          <Route path="/quiz/exam" element={<ExamQuiz />} />
          <Route path="/quiz/training" element={<TrainingQuiz />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

function MainLayout({ children }) {
  const location = useLocation();

  const hideNavBarAndFooter = ["/quiz/exam", "/quiz/training"].includes(
    location.pathname
  );

  return (
    <>
      {!hideNavBarAndFooter && <NavBar />}
      {children}
      {!hideNavBarAndFooter && <Footer />}
    </>
  );
}

export default App;
