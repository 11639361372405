import React, { useEffect, useRef, useState } from "react";
import "./quiz.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
// import { getCurrentUser } from "../auth/authController";
import GetSeriesHook from "../../hook/questions/get-series-hook";
import { Row, Col, Container } from "react-bootstrap";
import notify from "../../hook/useNotifaction";
import { ToastContainer } from "react-toastify";
import AudioPlayer from "../../components/audioPlayer";
import startButton from "../../assets/start-button.png";
import pauseButton from "../../assets/pause-button.png";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
const ExamQuiz = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const questionSerie = searchParams.get("questionSerie");
  const parsedDataArray = JSON.parse(decodeURIComponent(questionSerie));
  const intervalIdRef = useRef(null);

  const [AnswerInput, setAnswerInput] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [userResponses, setUserResponses] = useState([]);

  const [end, setEnd] = useState(false);
  const [timer, setTimer] = useState(null);

  const initialTime = 30;
  const [isTimerInitialized, setIsTimerInitialized] = useState(false);
  const [timerButton, setTimerButton] = useState(pauseButton);
  const [isMuted, setIsMuted] = useState(false);

  const [questions, totalPages, currentPage] = GetSeriesHook(parsedDataArray);

  function clearAnswerInput() {
    setAnswerInput("");
  }

  const handleAnswerInput = (number) => {
    if (AnswerInput.length < 4) {
      if (!AnswerInput.includes(number)) {
        setAnswerInput((prevClickedNumbers) => prevClickedNumbers + number);
      } else {
        setAnswerInput((prevClickedNumbers) =>
          prevClickedNumbers.replace(new RegExp(number, "g"), "")
        );
      }
    }
  };

  const moveToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setEnd(true);
    }
    setTimer(null);
  };

  const validateAnswer = () => {
    setTimer(null);
    const selectedAnswer = AnswerInput.split("")
      .sort()
      .map((number) => +number);

    setUserResponses((prevAnswers) => [
      ...prevAnswers,
      {
        _id: questions[currentQuestionIndex]._id,
        selectedAnswer,
      },
    ]);
  };

  const handleNextQuestion = () => {
    if (AnswerInput === "") {
      notify("أدخل الجواب أولا", "warn");
      return;
    }
    if (AnswerInput) {
      validateAnswer();
      setAnswerInput("");
    } else {
      setUserResponses((prevAnswers) => [
        ...prevAnswers,
        {
          _id: questions[currentQuestionIndex]._id,
          selectedAnswer: +"00",
        },
      ]);
    }
    moveToNextQuestion();
  };

  const handleEmptyQuestion = () => {
    setUserResponses((prevResponses) => [
      ...prevResponses,
      {
        _id: questions[currentQuestionIndex]._id,
        selectedAnswer: +"00",
      },
    ]);

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setTimer(null);
      clearAnswerInput();
    } else {
      if (isAnswerCorrect) {
        setQuizCompleted(true);
      }
    }
  };

  useEffect(() => {
    let intervalId;

    if (timer > 0 && isTimerInitialized) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(intervalId);
      handleEmptyQuestion();
    }

    return () => clearInterval(intervalId);
  }, [timer, isTimerInitialized]);

  const startTimer = () => {
    setTimer(initialTime);
    setIsTimerInitialized(true);
  };
  const handleAudioEnded = () => {
    startTimer();
  };

  const stopTimer = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
    setIsTimerInitialized(false);
  };
  const goTimer = () => {
    if (!isTimerInitialized) {
      // Only start if not already initialized
      setIsTimerInitialized(true);
    }
  };

  const handleTimerButton = (e) => {
    if (e.target.src === pauseButton) {
      setTimerButton(startButton);
      stopTimer();
    } else {
      setTimerButton(pauseButton);
      goTimer();
    }
  };
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const [timerAudio, setTimerAudio] = useState(null);
  const [showAudio, setShowAudio] = useState(false); // State to control audio display

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      setTimerAudio(2); // Set timer to 5 seconds
      setShowAudio(false); // Hide audio initially
      const countdown = setInterval(() => {
        setTimerAudio((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setShowAudio(true); // Show audio after timer ends
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Decrease timer every second

      return () => clearInterval(countdown); // Clean up on unmount
    } else {
      setShowAudio(true); // Always show audio if not the first question
    }
  }, [currentQuestionIndex]);

  return (
    <div className="quiz-zone">
      <div className="audio-volume">
        {isMuted ? (
          <FaVolumeMute onClick={toggleMute} />
        ) : (
          <FaVolumeUp onClick={toggleMute} />
        )}
      </div>
      <Container>
        <div className="quiz">
          {questions && questions.length > 0 && (
            <div>
              <img
                className="main-questions-image"
                src={questions[currentQuestionIndex].questionImage}
                alt="question"
              />

              {questions[currentQuestionIndex] &&
              questions[currentQuestionIndex].audio &&
              showAudio ? (
                <AudioPlayer
                  audioUrl={questions[currentQuestionIndex].audio}
                  onAudioEnded={handleAudioEnded}
                  muted={isMuted}
                />
              ) : (
                ""
              )}

              <div
                className="d-flex"
                style={{
                  marginTop: "25px",
                }}
              >
                <p
                  className="question text-light p-0 d-flex "
                  style={{ width: "fit-content" }}
                >
                  <div className="question-index">
                    <span className="number-font">
                      {currentQuestionIndex + 1}
                    </span>
                  </div>
                </p>
                <p className="question text-light">
                  {questions[currentQuestionIndex].question}
                </p>
                <p
                  className="question text-light p-0 d-flex m-0"
                  style={{ width: "fit-content" }}
                >
                  <div className="score">
                    <span className="number-font">
                      {timer === null ? `${initialTime}` : timer}
                    </span>
                  </div>
                </p>
              </div>

              <div className="question-area">
                {!questions[currentQuestionIndex].question2 ? (
                  <Row>
                    {questions[currentQuestionIndex].options.map(
                      (option, index) => (
                        <Col
                          sm={12}
                          md={6}
                          className="d-flex align-items-center"
                        >
                          <button
                            key={index}
                            className={
                              AnswerInput.includes(index + 1)
                                ? "op waiting number-font"
                                : "op number-font"
                            }
                            onClick={() => handleAnswerInput(index + 1)}
                          >
                            {`${index + 1}`}
                          </button>
                          <div className=" option bg-light">{`${option}`}</div>
                        </Col>
                      )
                    )}
                  </Row>
                ) : (
                  <>
                    {/* Render options for the first question */}
                    <Row>
                      {questions[currentQuestionIndex].options
                        .slice(0, 2)
                        .map((option, index) => (
                          <Col
                            sm={12}
                            md={6}
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <button
                              className={
                                AnswerInput.includes(index + 1)
                                  ? "op waiting number-font"
                                  : "op number-font"
                              }
                              onClick={() => handleAnswerInput(index + 1)}
                            >
                              {index + 1}
                            </button>
                            <div
                              className="option bg-light"
                              // className="option bg-light"
                            >
                              {option}
                            </div>
                          </Col>
                        ))}
                    </Row>

                    {/* Display the second question */}

                    <p className="question text-light">
                      {questions[currentQuestionIndex].question2}
                    </p>

                    {/* Render options for the second question */}
                    <Row>
                      {questions[currentQuestionIndex].options
                        .slice(2)
                        .map((option, index) => (
                          <Col
                            sm={12}
                            md={6}
                            key={index + 2}
                            className="d-flex align-items-center"
                          >
                            <button
                              className={
                                AnswerInput.includes(index + 3)
                                  ? "op waiting number-font"
                                  : "op number-font"
                              }
                              onClick={() => handleAnswerInput(index + 3)}
                            >
                              {index + 3}
                            </button>
                            <div
                              className="option bg-light"
                              // className="option bg-light"
                            >
                              {option}
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </>
                )}

                {end ? (
                  <Link
                    className="btn-number validate-button border-0"
                    to={`/result?result=${encodeURIComponent(
                      JSON.stringify(userResponses)
                    )}&questionSerie=${encodeURIComponent(
                      JSON.stringify(parsedDataArray)
                    )}`}
                  >
                    النتيجة
                  </Link>
                ) : (
                  <button
                    onClick={handleNextQuestion}
                    className="btn-number validate-button border-0 mb-3"
                  >
                    تأكيد
                  </button>
                )}
                <button
                  onClick={clearAnswerInput}
                  className="btn-number delete-button border-0"
                >
                  تصحيح
                </button>
              </div>
              {timer === null ? (
                <div className="timer-button-holder">
                  <img className="timer-button" src={timerButton} />
                </div>
              ) : (
                <div className="timer-button-holder">
                  <img
                    className="timer-button"
                    onClick={handleTimerButton}
                    src={timerButton}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ExamQuiz;
