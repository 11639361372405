import React, { useState, useEffect } from "react";
import { getAllSeries } from "../../redux/actions/questionAction";
import { useDispatch, useSelector } from "react-redux";

const GetSeriesHook = (serie) => {
  const [questions, setQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await dispatch(getAllSeries(serie || 1, 40));
      setLoading(false);
    };
    get();
  }, []);
  const res = useSelector((state) => state.question.questions);
  useEffect(() => {
    if (!loading) {
      // console.log(res);
      if (res && res.results) {
        setQuestions(res.results);
        setTotalPages(res.totalPages);
      }
    }
  }, [loading]);
  // console.log(questions);
  // console.log(totalPages);
  // console.log(currentPage);
  return [questions, totalPages, currentPage];
};

export default GetSeriesHook;
